import { formatISO } from 'date-fns';

export const getObservations = (domain, dateRange) => {
  let results = fetch(`//${domain}/getObservations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ beginDate: formatISO(dateRange.beginDate), endDate: formatISO(dateRange.endDate) })
  })
    .then(data => data.json())
    .catch((e) => {
      console.log(e);
      return {};
    });

  return results;
};