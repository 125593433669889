import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { saveToken } from '../Helpers/token';


// Create style classes for entire component
const useStyles = makeStyles((theme) => ({
  submit: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  otherBtn: {
    border: '1px solid rgb(100,100,100)'
  },
  btnCont: {
    width: '185px',
    height: '80px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  form: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    padding: '35px 25px 25px 25px',
    border: '1px solid black',
    borderRadius: '7px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white'
  },
  title: {
    fontSize: '34px'
  },
  divider: {
    background: theme.palette.primary.main,
    marginTop: '20px',
    marginBottom: '20px',
    height: '3px'
  },
  secondarydivider: {
    background: theme.palette.primary.main,
    marginTop: '15px',
    marginBottom: '15px',
    height: '2px',
    width: '80%',
    margin: '0 auto'
  },
  loginPage: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.12)',
  },
  errorMsg: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: '-150px',
    left: '0',
    width: '100vw',
    borderBottom: '1px solid black',
    padding: '20px',
    color: 'white',
    fontWeight: '800',
    backgroundColor: 'rgba(244, 147, 54, 1)',
  },
  visible: {
    top: '0px'
  },
  label: {
    marginTop: '10px',
    marginBottom: '10px',
  }
}));


// Function to log user in by making a request to the cloudflare worker endpoint
async function loginUser(domain, credentials) {
  let results = fetch(`//${domain}/verifyUser`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
    .catch(e => {
      console.log(e);
      return ({
        error: true,
        msg: 'An error has occurred. Please try again later.'
      });
    });

  return results;
}



export default function Login({ setToken, setUser, setContests, setForecasts, setIsAdmin, setLoading, domain }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errorMsg, setErrorMsg] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // eslint-disable-next-line no-undef
  let url = process.env.PUBLIC_URL + '/register';

  const classes = useStyles();

  // Makes the error message popup when errorMsg changes
  useEffect(() => {
    if (errorMsg !== '') {
      setShowErrorMessage(true);
    }
  }, [errorMsg]);

  // Causes error message to display for 5 seconds
  useEffect(() => {
    if (showErrorMessage) {
      const timeId = setTimeout(() => {
        setShowErrorMessage(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [showErrorMessage]);


  // On submit, ensure form is properly completed then try to log user in
  // Display error if necessary
  const handleSubmit = async e => {
    e.preventDefault();
    setErrorMsg('');

    setLoading(true);
    const result = await loginUser(domain, {
      username,
      password
    });
    
    if (result.exists && result.token) {
      setToken(result.token);
      setUser(username);
      setContests(result.contests);
      setForecasts(result.forecasts);
      
      if ('isAdmin' in result) {
        setIsAdmin(result.isAdmin);
      }
      
      saveToken({ user: username, token: result.token});
    } else if (result.exists && !result.token) {
      setErrorMsg('Incorrect Password.');
    } else if (result.error) {
      setErrorMsg(result.msg);
    } else {
      setErrorMsg('This username does not exist.');
    }
    setLoading(false);
  };

  return (
    <div className={classes.loginPage}>
      <div className={`${classes.errorMsg} ${showErrorMessage && classes.visible}`}>{errorMsg}</div>
      
      <form className={classes.form}>
        <Typography variant='h1' className={classes.title}>Forecast Contest</Typography>
        <Divider className={classes.divider}/>
        <Typography variant='h3'>Welcome! Please Log In</Typography>
        <Divider className={classes.secondarydivider}/>
        <label className={classes.label}>
          <Typography variant='h4'>Username</Typography>
          <input type="text" onChange={e => setUserName(e.target.value)} />
        </label>
        <label className={classes.label}>
          <Typography variant='h4'>Password</Typography>
          <input type="password" onChange={e => setPassword(e.target.value)} />
        </label>
        <div className={classes.btnCont}>
          <Button className={classes.submit} type='submit' onClick={handleSubmit}>Log In</Button>
          <Button className={classes.otherBtn} component={Link} to={url}>Create Account</Button>
        </div>
      </form>
    </div>
  );
}


Login.propTypes = {
  setToken: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setContests: PropTypes.func.isRequired,
  setForecasts: PropTypes.func.isRequired,
  setIsAdmin: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
};