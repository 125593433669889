import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Create style classes for entire component
const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'fixed',
    top: '64px',
    left: '241px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100vw - 242px)',
    height: 'calc(100vh - 64px)',
    backgroundColor: 'rgb(255,255,255)',
    boxSizing: 'border-box',
    zIndex: '1300',
    [theme.breakpoints.down('sm')]: {
      left: '0px',
      top: '56px',
      width: '100vw',
      height: 'calc(100vh - 56px)',
    }
  },
  full: {
    width: '100vw',
    height: '100vh',
    top: '0px',
    left: '0px'
  },
  progressCont: {
    flexBasis: '100%',
    textAlign: 'center',
    paddingBottom: '100px',
  },
}));

export default function Progress({ full }) {
  const classes = useStyles();

  return (
    <div className={`${classes.modal} ${full ? classes.full : ''}`}>
      <div className={classes.progressCont}>
        <Typography variant='h2' style={{ margin: '10px' }}>Loading...</Typography>
        <LinearProgress color='primary' />
      </div>
    </div>
  );
}

Progress.propTypes = {
  full: PropTypes.bool.isRequired
};