import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';


// Create style classes for entire component
const useStyles = makeStyles((theme) => ({
  userDataChangerPage: {
    boxSizing: 'border-box',
    padding: '10px 0px',
    overflowY: 'auto',
    height: 'calc(100vh - 64px)'
  },
  accordionCont: {
    maxWidth: '550px',
    margin: '0 auto'
  },
  paper: {
    backgroundColor: 'white',
    width: 'fit-content',
    padding: '10px',
    margin: '0 auto 20px auto',
    borderRadius: '7px',
    boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.5)'
  },
  summary: {
    paddingLeft: '0',
  },
  selected: {
    border: '1px solid gold',
    margin: '0 !important'
  },
  unselected: {
    border: '1px solid rgb(150,150,150)',
  },
  accordion: {
    flexDirection: 'column',
    padding: '8px 4px',
    borderRadius: '0px 0px 5px 5px',
    backgroundColor: 'rgba(167,169,20,0.02)',
    minWidth: '292px'
  },
  heading: {
    flexBasis: '100%',
    flexShrink: 0,
    textAlign: 'left',
    paddingLeft: '20px'
  },
  label: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  submit: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    marginTop: '15px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px',
    borderRadius: '7px',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.12)'
  },
}));


// Function to change user settings by making a request to the cloudflare worker endpoint
async function alterUser(domain, obj) {
  let results = fetch(`//${domain}/alterUser`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
    .then(data => data.json())
    .catch(e => {
      console.log(e);
      return 'An error has occurred. Please try again later.';
    });

  return results;
}

// Function to get list of users by making a request to the cloudflare worker endpoint
async function getUsers(domain) {
  let results = fetch(`//${domain}/getUsers`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(data => data.json())
    .catch(e => {
      console.log(e);
      return 'A server error has occurred. Please try again later.';
    });

  return results;
}



// Form component for preserving state per user
function EditUserForm({ name, handleSubmit }) {
  const [newUsername, setNewUserName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reTypedPassword, setReTypedPassword] = useState('');

  const classes = useStyles();

  return (
    <form className={classes.form}>
      <label className={classes.label}>
        <Typography variant='h4'>New Username</Typography>
        <input type="text" onChange={e => setNewUserName(e.target.value)} placeholder='Min. 6 characters, optional' />
      </label>

      <label className={classes.label}>
        <Typography variant='h4'>Password</Typography>
        <input type="password" onChange={e => setNewPassword(e.target.value)} placeholder='Min. 6 characters' />
      </label>

      <label className={classes.label}>
        <Typography variant='h4'>Re-type Password</Typography>
        <input type="password" onChange={e => setReTypedPassword(e.target.value)} placeholder='Re-type password' />
      </label>

      <Button className={classes.submit} type="submit" onClick={e => handleSubmit(e, name, newUsername, newPassword, reTypedPassword)}>Edit User</Button>
    </form>
  );
}

EditUserForm.propTypes = {
  name: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};



export default function UserDataChanger({ user, token, isAdmin, setLoading, setError, setSuccess, domain }) {
  // const [resMsg, setResMsg] = useState(false);
  const [userList, setUserList] = useState([]);
  const [expandedUser, setExpandedUser] = useState(false);
  
  const classes = useStyles();

  // Get the initial list of users
  useEffect(() => {
    setLoading(true);
    getUsers(domain)
      .then(data => {
        
        console.log(data);

        if (typeof data === 'string') {
          setError(data);
          setUserList([]);
        } else {
          setUserList(data);
        }
        
        setLoading(false);
      });
  }, []);

  // Handles choosing a different user accordion
  const handleUserChange = (panel) => (event, isExpanded) => {
    setExpandedUser(isExpanded ? panel : false);
  };

  // On submit, ensure form is properly completed then try to log user in
  // Display error if necessary
  const handleSubmit = async (e, name, newUsername, newPassword, reTypedPassword) => {
    e.preventDefault();

    console.log('handling submit');
    console.log(name, newUsername, newPassword, reTypedPassword);

    if (!isAdmin) {
      setError('You do not have administrative privileges. You can not edit users.');
    } else if (newPassword !== '' && reTypedPassword !== newPassword) {
      setError('The new passwords you supplied do not match.');
    } else if (newPassword !== '' && newPassword.length < 6) {
      setError('The new passwords you supplied are too short. They must be at least 6 characters.');
    } else if (newUsername !== '' && newUsername < 6) {
      setError('The new username you supplied is too short. It must be at least 6 characters.');
    } else if (newUsername === '' && newPassword === '') {
      setError('There is nothing to edit.');
    } else {
      setLoading(true);
      let response = await alterUser(domain, { user, token, oldUsername: name, newUsername, newPassword });
  
      console.log(222, response);

      if (!response.error) {
        setSuccess(response.msg);
        setUserList(response.newList);
      } else {
        setError(false);
        setError(response.msg);
      }

      setLoading(false);
    }
  };

  return (
    <div className={classes.userDataChangerPage}>
      <Typography className={classes.paper} variant='h2'>All Users</Typography>
      <div className={classes.accordionCont}>
        {userList.length > 0 ?
          userList.map( name => {
            return (
              <Accordion
                key={name}
                className={expandedUser === name ? classes.selected : classes.unselected}
                expanded={expandedUser === name}
                onChange={handleUserChange(name)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.summary}
                >
                  <Typography variant='h2' className={classes.heading}>{name}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordion}>
                  {
                    expandedUser === name && <EditUserForm name={name} handleSubmit={handleSubmit} />
                  }
                </AccordionDetails>
              </Accordion>
            );
          }) :
          <div className={classes.paper}>An error occurred while fetching the user list. Please try again later.</div>
        }
      </div>
    </div>
  );
}

UserDataChanger.propTypes = {
  user: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
};