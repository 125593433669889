import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Toolbar,
  Typography
} from '@material-ui/core';

import { Link } from 'react-router-dom';


// Create style classes for entire component
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: '#f77'
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    height: '56px',
    backgroundColor: 'red'
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'rgba(0,0,0,0.12)',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgb(224,224,224)',
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  navLinksCont: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    paddingTop: '0',
    paddingBottom: '0',
  },
  logout: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    margin: '0 auto',
  },
  icon: {
    color: 'rgb(110,110,110)',
    minWidth: 'fit-content',
    borderRadius: '50%',
    boxSizing: 'border-box',
    padding: '3px',
    marginRight: '15px',
    backgroundColor: 'gold',
  },
  selected: {
    backgroundColor: 'gold',
    '&:hover': {
      backgroundColor: 'gold'
    }
  },
  navHeading: {
    width: '100%'
  }
}));



export default function NavDrawer({ links, handleLogout }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navLinks, setNavLinks] = useState([]);
  const [selected, setSelected] = useState(0);
  
  const theme = useTheme();
  const classes = useStyles();


  // Opens and closes drawer when the screen is small enough
  const handleDrawerToggle = (e, close=false, number=false) => {
    setMobileOpen(close ? false : !mobileOpen);

    if (number !== false) {
      setSelected(number);
    }
  };


  // When links or selected page change creates nav links, highlights current page
  useEffect( () => {
    setNavLinks(
      <div key={'linkslist'}>
        <div className={classes.toolbar} />
        <List className={classes.navLinksCont}>
          {links.map((link, index) => {
            // eslint-disable-next-line no-undef
            let url = process.env.PUBLIC_URL + link.url;
            let dvdr = false;

            if (index === 4) {
              dvdr = <Divider />;
            }
            return (
              <React.Fragment key={index}>
                {dvdr || ''}
                <ListItem className={selected === index ? classes.selected : ''} button component={Link} to={url} onClick={e => handleDrawerToggle(e, true, index)}>
                  <ListItemIcon className={classes.icon}>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.text} />
                </ListItem>
              </React.Fragment>
            );
          })}
          <Divider />
          <ListItem>
            <Button className={classes.logout} onClick={handleLogout}>Log Out</Button>
          </ListItem>
        </List>
      </div>
    );
  }, [links, selected]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.navHeading}>
            <Typography variant="h1" noWrap>
              Forecast Contest
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <nav id='nav-drawer-cont' className={classes.drawer} aria-label="navigation links">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {navLinks}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {navLinks}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

NavDrawer.propTypes = {
  links: PropTypes.array.isRequired,
  handleLogout: PropTypes.func.isRequired
};