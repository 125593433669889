import React, { useState, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { parseISO, isBefore } from 'date-fns';
import ReactGA from 'react-ga4';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloudIcon from '@material-ui/icons/Cloud';
import CreateIcon from '@material-ui/icons/Create';
import DashBoardIcon from '@material-ui/icons/Dashboard';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PeopleIcon from '@material-ui/icons/People';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import '../Styles/App.scss';

import Settings from './Settings';
import Register from './Register';
import Login from './Login';
import Forecasts from './Forecasts';
import Contests from './Contests';
import Leaderboard from './Leaderboard';
import WeatherDataChanger from './WeatherDataChanger';
import ContestCreator from './ContestCreator';
import Progress from './Progress';
import NavDrawer from './NavDrawer';
import UserDataChanger from './UserDataChanger';
import CompletionRates from './CompletionRates';

import { getToken } from '../Helpers/token';
import { getObservations } from '../Helpers/observations';


// Domain for fetchs
// const DOMAIN = 'localhost:8787';
const DOMAIN = 'forecast-worker.benlinux915.workers.dev';


// eslint-disable-next-line quotes
const fonts = "'Alegreya Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif";
// eslint-disable-next-line quotes
const secondaryFonts = "'Roboto', 'Helvetica', 'Arial', sans-serif";


// Define custom theme for coloring and breakpoints
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 750,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: fonts,
    h1: {
      fontSize: '2.3rem'
    },
    h2: {
      fontSize: '1.5rem'
    },
    h3: {
      fontSize: '1.3rem'
    },
    h4: {
      fontSize: '1.0rem'
    },
    h5: {
      fontSize: '0.8rem',
      fontWeight: 600,
      fontFamily: secondaryFonts
    }
  },
  'palette': {
    'common': {
      'black': '#000',
      'white': '#fff'
    },
    'background': {
      'paper': '#fff',
      'default': 'rgba(242, 242, 242, 1)'
    },
    'primary': {
      'light': 'rgba(255, 101, 101, 1)',
      'main': 'rgba(255, 0, 0, 1)',
      'dark': 'rgba(181, 0, 0, 1)',
      'contrastText': '#fff'
    },
    'secondary': {
      'light': 'rgba(203, 203, 203, 1)',
      'main': 'rgba(157, 157, 157, 1)',
      'dark': 'rgba(99, 99, 99, 1)',
      'contrastText': '#fff'
    },
    'error': {
      'light': 'rgba(244, 147, 54, 1)',
      'main': 'rgba(244, 206, 54, 1)',
      'dark': 'rgba(238, 195, 19, 1)',
      'contrastText': 'rgba(0, 0, 0, 1)'
    },
    'text': {
      'primary': 'rgba(0, 0, 0, 0.87)',
      'secondary': 'rgba(0, 0, 0, 0.54)',
      'disabled': 'rgba(0, 0, 0, 0.38)',
      'hint': 'rgba(0, 0, 0, 0.38)'
    }
  }
});



function App() {
  const [user, setUser] = useState('');
  const [token, setToken] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [forecasts, setForecasts] = useState({});
  const [contests, setContests] = useState({});
  const [observations, setObservations] = useState({});
  const [mostRecent, setMostRecent] = useState('');
  const [loading, setLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const [success, setSuccess] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [updateObservations, setUpdateObservations] = useState(false);

  // Send analytics data to Google
  useEffect(() => {
    ReactGA.initialize('G-3CNDZ5VVGK');
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[]);

  // eslint-disable-next-line no-undef
  let url = process.env.PUBLIC_URL;


  // Shows error if error changes
  useEffect(() => {
    if (error !== '') {
      setShowError(true);
    }
  }, [error]);

  // Causes error to be shown for 5 seconds
  useEffect(() => {
    if (showError && error !== '') {
      const timeId = setTimeout(() => {
        setShowError(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    } else {
      setError('');
    }
  }, [showError]);

  // Shows success if success changes
  useEffect(() => {
    if (success !== '') {
      setShowSuccess(true);
    }
  }, [success]);

  // Causes success to be shown for 5 seconds
  useEffect(() => {
    if (showSuccess) {
      const timeId = setTimeout(() => {
        setShowSuccess(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    } else {
      setSuccess('');
    }
  }, [showSuccess]);
  
  // Check if localStorage has a token and verifies it
  useEffect(() => {
    getToken(DOMAIN)
      .then(info => {
        if (info?.isAdmin) {
          setIsAdmin(info?.isAdmin);
        }

        setUser(info.user);
        setToken(info.token);
        setContests(info.contests);
        setForecasts(info.forecasts);
        setLoading(false);
      });
  }, []);

  // If user is admin, adds admin only links to nav
  useEffect(() => {
    let newLinks = [
      {
        text: 'Dashboard',
        icon: <DashBoardIcon/>,
        url: '/'
      },
      {
        text: 'Join Contests',
        icon: <LibraryAddIcon/>,
        url: '/user/contests'
      },
      {
        text: 'View Leaderboard',
        icon: <EmojiEventsIcon/>,
        url: '/user/leaderboard'
      },
      {
        text: 'Settings',
        icon: <AccountCircleIcon/>,
        url: '/user/Settings'
      }
    ];
    
    if (isAdmin) {
      newLinks = [
        ...newLinks,
        {
          text: 'Contest Creator',
          icon: <CreateIcon/>,
          url: '/admin/setupContest'
        },
        {
          text: 'Edit Weather',
          icon: <CloudIcon/>,
          url: '/admin/alterWeatherData'
        },
        {
          text: 'Edit User Info',
          icon: <PeopleIcon/>,
          url: '/admin/editUser'
        },
        {
          text: 'User Completion Rates',
          icon: <TrendingUpIcon/>,
          url: '/admin/completionRates'
        }
      ];
    }

    setLinks(newLinks);
  }, [isAdmin, token, user]);

  // Gets observations for range of all contests user is enrolled in
  useEffect(() => {
    if (contests  && Object.keys(contests).length > 0) {
      let dateRange = Object.values(contests).reduce((acc, c) => {
        let bDate = parseISO(c.beginDate);
        let eDate = parseISO(c.endDate);
  
        if (!acc.beginDate || isBefore(bDate, acc.beginDate)) {
          acc.beginDate = bDate;
        }
  
        if (!acc.endDate || isBefore(acc.endDate, eDate)) {
          acc.endDate = eDate;
        }
  
        return acc;
      }, { beginDate: null, endDate: null });
  
      setLoading(true);
      getObservations(DOMAIN, dateRange)
        .then(info => {
          setObservations(info.obs);
          setMostRecent(info.mostRecent);
          setLoading(false);
        });
    }
  }, [contests, updateObservations]);

  // On clicking button, removes localStorage data and clears state to log user out
  const handleLogout = () => {
    localStorage.setItem('userInfo', JSON.stringify({ user: '', token: '' }));
    setToken('');
    setUser('');
    setForecasts({});
    setContests({});
    setIsAdmin(false);
    setObservations({});
    setMostRecent('');
    setLinks([]);
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            {!token ? <>
              {loading && <Progress full={true}/>}
              <Route path={url + '/login'}>
                <Login
                  setToken={setToken}
                  setUser={setUser}
                  setContests={setContests}
                  setForecasts={setForecasts}
                  setIsAdmin={setIsAdmin}
                  setLoading={setLoading}
                  domain={DOMAIN}
                />
              </Route>
              <Route path={url + '/register'}>
                <Register
                  setToken={setToken}
                  setUser={setUser}
                  setContests={setContests}
                  setForecasts={setForecasts}
                  setLoading={setLoading}
                  domain={DOMAIN}
                />
              </Route>
              <Redirect from={url + '/'} to={url + '/login'}></Redirect>
            </> : <>
              {loading && <Progress full={false}/>}
              <header>
                <NavDrawer
                  links={links}
                  handleLogout={handleLogout}
                />
              </header>
              <div id='body'>
                <div className={`app-error-msg ${showError && 'app-visible'}`}>{error || ''}</div>
                <div className={`app-success-msg ${showSuccess && 'app-visible'}`}>{success}</div>

                <Route path={url + '/user/settings'} exact>
                  <Settings 
                    user={user}
                    setUser={setUser}
                    token={token}
                    setLoading={setLoading}
                    setError={setError}
                    setSuccess={setSuccess}
                    domain={DOMAIN}
                  />
                </Route>
                <Route path={url + '/user/contests'} exact>
                  <Contests
                    userContests={contests}
                    setUserContests={setContests}
                    setUserForecasts={setForecasts}
                    user={user}
                    token={token}
                    isAdmin={isAdmin}
                    setLoading={setLoading}
                    setError={setError}
                    setSuccess={setSuccess}
                    domain={DOMAIN}
                  />
                </Route>
                <Route path={url + '/user/leaderboard'} exact>
                  <Leaderboard 
                    contestList={Object.keys(contests)}
                    user={user}
                    setLoading={setLoading}
                    setError={setError}
                    setSuccess={setSuccess}
                    domain={DOMAIN}
                  />
                </Route>
                <Route path={url + '/admin/alterWeatherData'} exact>
                  <WeatherDataChanger 
                    user={user}
                    token={token}
                    isAdmin={isAdmin}
                    setForecasts={setForecasts}
                    setUpdateObservations={setUpdateObservations}
                    setLoading={setLoading}
                    setError={setError}
                    setSuccess={setSuccess}
                    domain={DOMAIN}
                  />
                </Route>
                <Route path={url + '/admin/setupContest'} exact>
                  <ContestCreator
                    user={user}
                    token={token}
                    isAdmin={isAdmin}
                    setLoading={setLoading}
                    setError={setError}
                    setSuccess={setSuccess}
                    domain={DOMAIN}
                  />
                </Route>
                <Route path={url + '/admin/editUser'} exact>
                  <UserDataChanger
                    user={user}
                    token={token}
                    isAdmin={isAdmin}
                    setLoading={setLoading}
                    setError={setError}
                    setSuccess={setSuccess}
                    domain={DOMAIN}
                  />
                </Route>

                <Route path={url + '/admin/completionRates'} exact>
                  <CompletionRates
                    user={user}
                    token={token}
                    isAdmin={isAdmin}
                    setLoading={setLoading}
                    setError={setError}
                    setSuccess={setSuccess}
                    domain={DOMAIN}
                  />
                </Route>
        
                <Redirect from={url + '/login'} to={url + '/'}></Redirect>
                <Redirect from={url + '/register'} to={url + '/'}></Redirect>
        
                <Route path={url + '/'} exact>
                  <Forecasts
                    user={user}
                    token={token}
                    contests={contests}
                    forecasts={forecasts}
                    setForecasts={setForecasts}
                    observations={observations}
                    mostRecent={mostRecent}
                    setLoading={setLoading}
                    setError={setError}
                    setSuccess={setSuccess}
                    domain={DOMAIN}
                  />
                </Route>
              </div>
            </>}
            <Redirect from="*" to={url + '/'}></Redirect>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;